import {
  XHRClient,
  XHRClientCredentials,
  XHRClientAuth,
  GET_USER_SESSION,
  GET_SSO_RETURN,
  GET_USER_SETTINGS,
  POST_USER_LOGOUT,
  POST_FORGOT,
  POST_ACTIVATE,
  POST_RESET,
  FEDERATION_URL,
  QUERY_CURRENT_USER,
  ProxyType,
} from 'Graph';

import { ApplyWhiteLabel, ApplyDomainWhiteLabel, AdjustConfig, AdjustNavigationSSO, StoreConfigMetaData } from './shared/';

import { CoreBootAuthPlugins, CoreConfig, CoreConfigAccount, CoreConfigCompany, CoreGraphLink, CoreGraphDataFactory, CoreConfigHeader } from '@neustar/core-ui';
import { IsNull, Noop } from '@neustar/core-ui/lib/utils/';

/**
 * Authenticate Plugin
 * @async
 * @export
 * @param {CoreAuthServiceType} context
 * @returns {Promise<void>}
 */
async function AuthenticatePlugin(context: CoreAuthServiceType): Promise<any> {
  const XHRClientAuthInstance = XHRClientAuth(context);

  try {
    // get session
    const response: HashMap<any> = await XHRClientAuthInstance.query({ query: GET_USER_SESSION }).catch((err: any) => {
      throw err;
    });

    const { active } = CoreGraphDataFactory(response, 'session', {}) as HashMap<any>;

    if (!active) {
      throw new Error('Plugin::AuthenticatePlugin[GET_USER_SESSION]:: failed.');
    }
  } catch (err: any) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(err.message || err);
    }

    (window as any).alert('Session not established. Please see your representative.');

    return false;
  }

  try {
    // get sso return to
    const response: HashMap<any> = await XHRClientAuthInstance.query({ query: GET_SSO_RETURN }).catch((err: any) => {
      throw err;
    });

    const { return_to } = CoreGraphDataFactory(response, 'sso', {}) as HashMap<any>;

    if (return_to) {
      (window as any).location.href = return_to;
    }
  } catch (err: any) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(err.message || err);
    }
  }

  return true;
}

/**
 * User Plugin
 * @async
 * @export
 * @param {CoreAuthServiceType} context
 * @returns {Promise<void>}
 */
export async function UserPlugin(context: CoreAuthServiceType): Promise<void> {
  try {
    // silent: true -- MUST BE THERE FOR AUTHENTICATION.
    const client = CoreGraphLink({ uri: FEDERATION_URL, silent: true }, context).client;
    const result: any = await client.query({ query: QUERY_CURRENT_USER }).catch(console.error);
    const response = CoreGraphDataFactory(result, 'company', {}) as HashMap<any>;

    const { dName, whiteLabel } = response;
    let { proxyPackage, bgpPackage, detectionAndAlertingPackage: dnaPackage, apiPackage, ssoPackage, isReseller } = response;

    if (dName) {
      context.user.profile.account = dName;
    }

    /**
     * CoreConfigCompany
     * - operations must go together and be applied AFTER USER QUERY
     */
    CoreConfigCompany.DNAME = context.user.account;
    if (!CoreConfig.company.dName) {
      CoreConfig.company.clear();
    }

    const XHRClientInstance = XHRClientCredentials();
    const { loading, data } = await XHRClientInstance.query({ query: GET_USER_SETTINGS }).catch(Noop);
    const { masquerade, config, user, federated } = CoreGraphDataFactory({ loading, data }, 'settings', {}) as HashMap<any>;
    const tunnelTypes = config.tunnelTypes;
    CoreConfigHeader.SHORT_NAME = user?.shortname;
    if (masquerade) {
      context.user.profile.masquerade = true;
      context.user.profile.account = user.account;
      const { name, shortname, picture, id } = user;
      Object.assign(context.user.profile, { shortname });
      Object.assign(context.user.profile.userinfo, { name, picture, id });
      CoreConfigHeader.SHORT_NAME = shortname;
      CoreConfig.company.dName = user.account;
      CoreConfigAccount.SSO = federated === true;

      isReseller = config.reseller;
      proxyPackage = config.proxyPackage;
      bgpPackage = config.bgpPackage;
      dnaPackage = config.dnaPackage;
      apiPackage = config.apiPackage;
      ssoPackage = config.ssoPackage;

      await AdjustNavigationSSO();

      if (config.whiteLabel) {
        await ApplyDomainWhiteLabel(config.whiteLabel).catch(console.error);
        if (config.whiteLabel?.enabled) {
          StoreConfigMetaData('FAVICON');
        }
      }
    }

    if (!proxyPackage.proxyType) {
      if (!IsNull(proxyPackage.networkEnabled)) {
        proxyPackage.proxyType = proxyPackage.networkEnabled ? ProxyType.CLOUD : ProxyType.HARDWARE;
      }
    }

    if (!IsNull(proxyPackage?.apiEnabled)) {
      proxyPackage.api = proxyPackage.apiEnabled;
    }

    if (!IsNull(proxyPackage?.edgeEnabled)) {
      proxyPackage.edge = proxyPackage.edgeEnabled;
    }

    if (!IsNull(bgpPackage?.routing)) {
      bgpPackage.bgpRouting = bgpPackage.routing;
    }

    await ApplyWhiteLabel(whiteLabel).catch(console.error);
    if (whiteLabel?.enabled) {
      StoreConfigMetaData('THEME');
    }
    await AdjustConfig({ isReseller, proxyPackage, bgpPackage, dnaPackage, apiPackage, ssoPackage, tunnelTypes }).catch(console.error);
  } catch (err: any) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(err.message || err);
    }
  }
  return void 0;
}

/**
 * Logout Plugin
 * @async
 * @export
 * @param {CoreAuthServiceType} context
 * @returns {Promise<void>}
 */
async function LogoutPlugin(context: CoreAuthServiceType): Promise<void> {
  try {
    const XHRClientInstance = XHRClientAuth(context);

    await XHRClientInstance.mutate({ mutation: POST_USER_LOGOUT }).catch(Noop);

    CoreConfig.company.destroy();
  } catch (err: any) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(err.message || err);
    }
  }
  return void 0;
}

/**
 * Forgot Plugin
 * @async
 * @param {HashMap<any>} { email }
 * @returns {Promise<void>}
 */
async function ForgotPlugin({ email }: HashMap<any>): Promise<void> {
  try {
    const XHRClientInstance = XHRClientCredentials();
    const { data } = await XHRClientInstance.mutate({ mutation: POST_FORGOT, variables: { input: { email } } }).catch(Noop);
    const { response, error } = CoreGraphDataFactory({ loading: false, data }, 'forgot', {}) as HashMap<any>;

    if (response) {
      (window as any).alert(response);
    }

    if (error) {
      throw new Error(error);
    }
  } catch (err: any) {
    (window as any).alert(err.message || err);
  }
  return void 0;
}

/**
 * Activate Plugin
 * @async
 * @param {HashMap<any>} { token, email, password, confirm }
 * @returns {Promise<void>}
 */
async function ActivatePlugin({ token, email, password, confirm }: HashMap<any>): Promise<void> {
  try {
    const domain: string[] = email.split('@')[1].split('.');
    const ldap: boolean = !!~domain.indexOf('neustar');
    const verify = confirm;

    const XHRClientInstance = XHRClient();
    const { data } = await XHRClientInstance.mutate({ mutation: POST_ACTIVATE, variables: { input: { token, email, password, verify, ldap } } }).catch(Noop);
    const { response, error } = CoreGraphDataFactory({ loading: false, data }, 'activate', {}) as HashMap<any>;

    if (response) {
      (window as any).alert(response);
    }

    if (error) {
      throw new Error(error);
    }
  } catch (err: any) {
    (window as any).alert(err.message || err);
  }
  return void 0;
}

/**
 * Reset Plugin
 * @async
 * @param {HashMap<any>} { token, email, password, confirm }
 * @returns {Promise<void>}
 */
async function ResetPlugin({ token, email, password, confirm }: HashMap<any>): Promise<void> {
  try {
    const domain: string[] = email.split('@')[1].split('.');
    const ldap: boolean = !!~domain.indexOf('neustar');
    const verify = confirm;

    const XHRClientInstance = XHRClient();
    const { data } = await XHRClientInstance.mutate({ mutation: POST_RESET, variables: { input: { token, email, password, verify, ldap } } }).catch(Noop);
    const { response, error } = CoreGraphDataFactory({ loading: false, data }, 'reset', {}) as HashMap<any>;

    if (response) {
      (window as any).alert(response);
    }

    if (error) {
      throw new Error(error);
    }
  } catch (err: any) {
    (window as any).alert(err.message || err);
  }
  return void 0;
}

// Registered as Boot Plugins
CoreBootAuthPlugins.register('authenticate', AuthenticatePlugin);
CoreBootAuthPlugins.register('user', UserPlugin);
CoreBootAuthPlugins.register('logout', LogoutPlugin);
CoreBootAuthPlugins.register('forgot', ForgotPlugin);
CoreBootAuthPlugins.register('activate', ActivatePlugin);
CoreBootAuthPlugins.register('reset', ResetPlugin);
